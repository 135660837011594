@import 'styles/variables/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
  margin-top: 40px;
  color: $dark-gray;

  &__mobile {
    gap: 24px;
    margin-top: 24px;
    margin-right: -16px;
    margin-left: -16px;
  }
}

.wrapper {
  background-color: #f5f5f5;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  border-radius: 4px;
  position: relative;
  &__row {
    flex-direction: row;
    justify-content: center;
    gap: 60px;
  }

  &__mobile {
    padding: 40px 16px;
  }
}

.description {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  &__title {
    font-size: 20px;
    font-weight: 700 !important;
    line-height: 30px;
    margin: 0;

    &__mobile {
      font-size: 22px;
      font-weight: 700 !important;
    }
  }
  &__text {
    line-height: 25px;
    font-size: 16px;
    margin: 0;
    &__center {
      text-align: center;
    }
  }
  &__desktop {
    order: 1;
    width: 350px;
    align-items: flex-start;
  }
}

.expandBtn {
  font-size: 24px;
  position: absolute;
  top: 24px;
  right: 24px;
  color: #303030;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 30px;
  border-radius: 30px;
  border: none;
  background: none;
  &:hover {
    cursor: pointer;
    background-color: #ececec;
  }
}

.viewMore {
  width: 200px;
  height: 40px;

  &__topMargin {
    margin-top: 20px;
  }

  &__mobile {
    height: 42px;
    font-size: 16px;

    button {
      font-size: 14px;
    }
  }
}
