.refresh {
  border: none;
  background-color: #dddddd;
  border-radius: 4px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  min-width: 90px;
  outline: none;

  &__font {
    font-size: 21px;
    height: auto !important;
    width: auto !important;
    margin-top: 2px;
    color: #303030;
  }

  &:hover, &:disabled {
    background-color: #C0C0C0;
  }

  &__mobile {
    padding: 8px 12px;
    min-width: 72px;

    &:hover {
      background-color: #dddddd;
    }

    &:disabled {
      background-color: #C0C0C0;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 1.66;
    color: inherit;

    &__mobile {
      font-size: 14px;
    }
  }

  &__icon {
    width: 12px;
    height: 12px;

    &__animation {
      animation-name: reload;
      animation-iteration-count: infinite;
      animation-duration: 1s;
    }

    &__mobile {
      width: 16px;
      height: 16px;
    }
  }

  &__noFirstLoaded {
    &:disabled {
      background-color: #dddddd;
    }

    &__label, &__icon {
      color: #666666;
    }

    &__icon {
      fill: #666666;
    }
  }
}

@keyframes reload {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
