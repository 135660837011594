.tabs {
  border-top: 1px solid #666666;
  background-color: #444444;
  display: flex;
  height: 42px;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 50%;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
  &__active {
    background-color: #303030;
    font-weight: 700 !important;
  }
}
