.container {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: #3f3f3f;
}

.title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700 !important;
  text-align: center;
  color: inherit;
  flex-grow: 1;
}

.searchBtn {
  max-width: 42px;
  width: 42px;
  height: 42px;
  display: flex;
  border: solid 1px #666666;
  border-radius: 4px;
  &__icon {
    color: inherit;
    font-size: 24px;
  }

  &:active {
    color: #ffffff !important;
    background-color: #666666 !important;
    border-color: #666666 !important;
  }
}

.emptyBlock {
  width: 42px;
}
