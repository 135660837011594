.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  min-width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1100;
  overflow-y: auto;
}

.filters {
  display: flex;
  flex-direction: column;
  padding: 0 16px 40px 16px;
  background-color: #fff;
  font-size: 16px;
}
