.odds {
  padding: 0 5px;
  border-radius: 2px;

  i {
    margin-left: 5px;
    font-size: 6px;
  }

  &__up {
    border: 1px solid #04A04A;
    background: #CBECD8;

    i {
      color: #04A04A;
    }
  }

  &__down {
    border: 1px solid #D70022;
    background: #FAC9D1;

    i {
      color: #D70022;
    }
  }

  &__bold {
    font-weight: bold !important;
  }

  &__root {
    width: fit-content;
    height: 25px;
    display: flex;
    align-items: center;
    gap: 2px;
    border: 1px solid transparent;
    box-sizing: border-box;
    font-size: 14px;
    margin-top: -7px;
  }
}
