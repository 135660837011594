@import 'styles/mixins/breakpoints';

.mobile_toolbar {
  display: none;

  @include md {
    display: grid;
    grid-auto-flow: column;
    position: fixed;
    z-index: 1200;
    bottom: 0;
    left: 0;
    width: 100vw;
    border-top: 1px solid;
    background-color: #525252;
    border-color: #525252;
    color: #dcdcdc;

    a,
    a:hover {
      text-decoration: none;
    }
  }

  .betListIcon {
    font-size: 16px !important;
    min-width: auto;
    min-height: auto;
    line-height: 20px;
  }

  &.drawer {
    display: flex;
    flex-direction: column;
    width: 199px;
    position: absolute;
    z-index: 1000;
    top: 0;
    background-color: #303030;
    transition: width 0.8s;
    visibility: visible;
    overflow: hidden;
    height: 100dvh;
    color: #ffffff;
    .backdrop {
      width: 100%;
      background-color: #000000;
      opacity: 0.5;
      height: 100dvh;
      top: 0;
    }
    .item {
      width: 199px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 10px 12px;
      min-height: 60px;
      gap: 12px;
      box-sizing: border-box;

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        background: none !important;
      }

      .betListIcon {
        font-size: 16px !important;
        min-width: auto;
        min-height: auto;
        line-height: 20px;
      }

      .counter {
        bottom: calc(50% + 4px);
        left: 8px;
      }
    }
    &__hide {
      width: 0px !important;
    }
    &__menu {
      border-top: none;
      width: 80px;
      bottom: 0;
      transition: initial;
      .item {
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        width: 80px;
        i {
          font-size: 22px !important;
        }
        span {
          font-size: 12px;
        }

        .betListIcon {
          font-size: 16px !important;
          min-width: auto;
          min-height: auto;
          line-height: 20px;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
  padding: 4px 0;

  &__icon {
    min-height: 25px;
    min-width: 25px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    font-size: 25px;

    &__asianView {
      font-size: 20px;
      line-height: 1;
      min-height: 20px;
      min-width: 20px;
    }
  }
}

.cashOut {
  color: #ffb80c;
  border-radius: 50%;
  border: 1px solid #ffb80c;
  width: 21px;
  height: 21px;
  position: relative;

  &:before {
    color: #ffb80c;
    position: absolute;
    font-size: 9px;
    top: 6px;
    left: 7px;
  }
}

.cashOutOrbit {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: relative;

  &:before {
    color: inherit;
    position: absolute;
    font-size: 9px;
    top: 6px;
    left: 7px;
  }
}

.disabledItem {
  opacity: 0.5;
}

.overlay {
  position: absolute;
  width: 100%;
  background: black;
  z-index: 10;
  opacity: 0.5;
  flex-direction: column;
  bottom: 0;
  display: none;
  height: 100dvh;
  top: 0;
  &__visible {
    display: block;
    animation: fadeIn 1.5s;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

.iconWrapper {
  position: relative;
  overflow: visible;
}

.counter {
  position: absolute;
  left: 2px;
  bottom: 50%;
  background-color: #ffb80c;
  border-radius: 16px;
  padding: 0 4px;
  transform: translateX(-100%);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  font-size: 10px;
  color: #3e3e3e;
  box-sizing: border-box;

  &__right {
    box-sizing: border-box;
    transform: initial;
    left: initial;
    right: -4px;
  }
  &__moreThanDoubleDigitNumber {
    border-radius: 16px;
    padding: 0 4px;
  }

  &__moreThanDoubleDigitNumberDrawer {
    left: 16px !important;
  }

  &__moreThanTripleDigitNumberDrawer {
    left: 24px !important;
  }

  p {
    line-height: 1;
    margin: 0;
    font-weight: 500 !important;
  }
}

.asianMoreLink {
  height: auto;

  &__drawerItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 12px;
    column-gap: 12px;
    align-items: center;
    min-height: 60px;

    span {
      margin-top: 0;
      font-size: 14px;
    }
  }
}

.asianViewToolbar {
  border-top-width: 0;
}

.itemLabel {
  text-align: center;
}
