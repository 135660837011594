@import 'styles/variables/variables.scss';
@import 'src/styles/mixins/breakpoints';

.content {
  box-sizing: content-box;
  max-width: 1320px;
  margin: auto;
  padding: 40px 60px;

  @media (max-width: $md) {
    padding: 24px 16px;
  }
}

.guideContent {
  display: flex;
  flex-direction: column;
  gap: 70px;
  max-width: 800px;
  margin: auto;

  @media (max-width: $sm) {
    gap: 54px;
  }
}

.backLink {
  display: inline-flex;
  align-items: center;
  margin-left: -15px;
  gap: 5px;
  text-decoration: underline;
  color: $blue;
  font-size: 14px;

  &:hover {
    text-decoration: none;
  }

  i {
    color: currentColor;
  }
}

.viewAllSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;

  h2 {
    margin: 0;
    line-height: 28px;
    font-size: 18px;
    font-weight: 700 !important;
  }

  button {
    padding: 8px 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
}

.contentSection {
  @media (max-width: $sm) {
    padding-top: 12px;
  }

  & > article {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 0;

    @media (max-width: $sm) {
      gap: 16px;
    }
  }

  p {
    font-size: 16px;
    line-height: 25px;
    margin: 0;

    b {
      font-weight: 700 !important;
    }
  }

  // Title
  p:first-of-type {
    margin-bottom: 5px;

    @media (max-width: $sm) {
      margin-bottom: 0;
    }

    b {
      font-size: 24px;
      line-height: 30px;

      @media (max-width: $sm) {
        line-height: 32px;
      }
    }
  }

  &__desktop {
    margin-top: -50px;
  }

  img {
    max-width: 100%;
    height: auto;

    &:first-of-type {
      margin-bottom: 55px;

      @media (max-width: $sm) {
        margin-bottom: 38px;
      }
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.nextInTheRowSection {
  padding: 20px 0;

  @media (max-width: $sm) {
    padding: 12px 0;
  }
}
