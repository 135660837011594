@import 'src/styles/mixins/breakpoints';

.container {
  position: relative;

  &__topText {
    font-weight: bold !important;

    &__avMobile {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 600 !important;
      color: #303030;
    }

    &__ignoreFlexibleDisplayFormat {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  &__profitText {
    &__avMobile {
      font-size: 12px;
      line-height: 1.5;
    }
  }
}

.containerBetslip {
  width: 160px;
  margin: 0;
}

.cashOutBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  min-height: 37px;
  margin: 0 auto;
  padding: 2px 0;
  background-color: #ffb80c;
  border: 0;
  border-radius: 2px;
  cursor: pointer;

  &__negative {
    color: #b30000;
  }

  &__betslip {
    width: 100%;
    margin: 0;
  }

  @media (min-width: $md) and (max-width: 1200px) {
    width: 140px;
  }

  &__avMobPage,
  &__avEventTab {
    width: 100%;
    border-radius: 4px;
    min-height: 48px;
    height: 48px;
  }
}

.disabled {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  opacity: 0.5;
  z-index: 3;
}

.containerAvMobilePage,
.avEventTab {
  flex: 1;
  margin: 0;
}
