.pagination {
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;

  &__mobile {
    padding-top: 24px;
    flex-direction: column;
    gap: 16px;
  }

  &__wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__left,
  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}

.btnList {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.btn {
  all: unset;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  color: #303030;
  background-color: #ddd;
  cursor: pointer;

  &__mobile {
    width: 42px;
    height: 42px;
  }

  &:disabled {
    background-color: #ececec;
    color: #c0c0c0 !important;
    cursor: initial;
  }

  &:not(:disabled):hover {
    background-color: #c0c0c0;
  }
}

.placeholder {
  width: 100px;
}

.icon {
  font-size: 16px;
  color: inherit;

  &__mobile {
    font-size: 24px;
  }
}

.label {
  margin: 0;

  &__mobile {
    font-size: 16px;
    color: #666666;
  }
}
