@import '../../styles/variables/variables';
@import '../../styles/mixins/breakpoints';

.sortBy {
  height: 22px;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  color: inherit;
  box-sizing: border-box;
  white-space: nowrap;
  border: none;
  background-color: transparent;
}

.iconCaret {
  transform: rotate(90deg);

  &::before {
    font-size: 12px !important;
    color: inherit !important;
  }
}

.iconDoubleCaret {
  &::before {
    font-size: 16px !important;
    color: inherit !important;
  }
}
