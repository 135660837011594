.container {
  width: 220px;
}

.placeholder {
  position: relative;
  min-height: 30px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 12px;
  font-style: normal;
  line-height: 15px;
  color: #3e3e3e;
  text-transform: capitalize !important;
  height: 30px;

  &::after {
    top: 50%;
    right: 20px;
    color: #303030;
    content: '\e92c';
    font-size: 16px !important;
  }

  &__default {
    color: #999;
  }
}

.placeholder:hover,
.activePlaceholder {
  border: 1px solid #000;

  &_disabled {
    border: 1px solid red !important;
  }
}

.dropdown {
  padding: 5px 0;
  margin-top: 4px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 #181e5c26;
}

.option {
  box-sizing: border-box;
  height: 30px;
  min-width: 170px;
  line-height: 20px;
  padding: 0 30px 0 10px;
  font-size: 12px;
  text-transform: capitalize;

  &:hover {
    background-color: #ececec;
  }

  &:last-child {
    border: none;
  }
}

.selectedOption {
  font-weight: 700 !important;

  &::after {
    font-family: 'custom-icon-font', serif !important;
    font-weight: normal !important;
    font-style: normal !important;
    content: '\e922' !important;
    position: absolute;
    font-size: 16px;
    right: 10px;
  }
}
