@import 'styles/mixins/breakpoints';

.container {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.content {
  display: flex;
  gap: 10px;

  @include md {
    width: 100%;
    padding: 0 10px;
    justify-content: space-between;
  }

  &__right {
    display: flex;
    gap: 10px;
  }
}

.liability {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;

  &__value {
    font-weight: bold !important;
  }

  p {
    margin: 0;
  }
}

.setting {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  width: 37px;
  height: 37px;
  background-color: #c0c0c0;
  border-radius: 2px;
  border-width: 0;
  color: #444444;
  cursor: pointer;
  position: relative;

  &__coMobPage {
    border-radius: 4px;
    width: 48px;
    height: 48px;

    i {
      color: inherit;
    }
  }

  .indicator {
    position: absolute;
    font-size: 9px;
    top: -3px;
    right: -3px;
    color: #04a04a;
    background: #04a04a;
    border-radius: 50%;
  }

  &.settingAutoCashOutActive i {
    color: inherit;
  }
}

.activeSetting {
  background-color: #3e3e3e;
  color: #ffffff;

  i {
    color: inherit;
  }
}

.cashOutBetslip {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 5px;
}

.coAVMobPage {
  gap: 8px;
  padding: 0 10px;
}

.eventTabLiability {
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;

  p {
    font-size: 14px !important;
    line-height: 1.5;
  }
}

.avEventTab {
  padding: 0 12px 15px;

  &__progressBar {
    margin: 15px 12px;
  }
}
