@import "../../styles/mixins/breakpoints";
@import "../../styles/variables/variables";

.inPlay {
  display: flex;
  align-items: center;

  &__status {
    font-size: 12px;
    line-height: 10px;
    display: flex;
    padding: 2px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    text-align: center;
    background-color: $light-gray;
    color: $white;
    box-sizing: border-box;
    position: relative;

    &__showLabel {
      font-size: 16px;
      line-height: 1;
    }
  }

  &__label {
    font-size: $small;
    color: #273a47;
    margin-right: 6px;
    margin-left: 6px;
    line-height: 17px;
  }
}
