.list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.viewAll {
  &__top {
    background-color: #ffffff;
    padding: 12px 16px;
    display: flex;
    &__link {
      text-align: center;
      padding: 10px 0;
      flex-grow: 1;
      border: solid 1px #dddddd;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-decoration: underline;
      color: #0072ac;
    }
  }
  &__bottom {
    text-align: center;
    padding: 38px 16px 46px 16px;
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
      margin-bottom: 12px;
    }
    &__link {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-decoration: underline;
      color: #0072ac;
    }
  }
}
