.container {
  min-width: 65px;
  max-width: 65px;
  position: relative;
  z-index: 20;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    text-align: center;
  }
}
