.skeletonHeader {
  display: flex;
  gap: 12px;
  padding: 12px 16px;
  background-color: #ffffff;
  margin-bottom: 16px;

  &__link {
    flex: 1;
    height: 42px;
  }

  &__btn {
    width: 42px;
    height: 42px;
  }
}

.skeletonItem {
  padding: 24px 16px 20px;
  background-color: #ffffff;
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-bottom: 12px;

  &__s {
    height: 16px;
    width: 40px;
  }
  &__m {
    height: 16px;
    width: 140px;
  }
  &__l {
    height: 16px;
    width: 200px;
  }
  &__top {
    display: flex;
    gap: 16px;

    &__divided {
      padding-bottom: 4px;
    }

    &__left {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 11px;
    }
    &__right {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__divided {
      padding-top: 16px;
      border-top: 1px solid #ddd;
    }

    &__main {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__secondary {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
