.link {
  display: flex;
  align-items: center;
  border-bottom: solid 1px #dddddd;
  flex: 1;
  padding: 10px 0;
  gap: 20px;
  width: 100%;
  color: #303030 !important;
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 25px;
  text-decoration: none;

  &__mobile {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    padding: 20px 0;
    gap: 16px;

    &:active {
      text-decoration: underline;
    }
  }

  &:last-child {
    border: none;
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;

    .image {
      filter: brightness(60%);
    }
  }
}

.image {
  max-width: 100px;
  max-height: 60px;
  border-radius: 4px;
  height: auto;
  object-fit: cover;
  aspect-ratio: 5/3;
  &__hide {
    display: none;
  }
  &__mobile {
    max-width: 120px;
    max-height: 90px;
  }
}
