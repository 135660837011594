@import '../../styles/variables/variables';
@import '../../styles/mixins/breakpoints';

.scrollWrapper {
  width: 100%;
  padding: 0 0 5px 5px;
  box-sizing: border-box;

  &:nth-last-child(1) {
    padding: 5px 0 0 5px;
  }

  span {
    display: block;
    width: 100%;
    height: 18px;
    border: 0;
    border-radius: 2px;
    background-color: #efefef;
    text-align: center;
    line-height: 16px;
    outline: 0;
    font-size: 12px;
    cursor: pointer;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    i {
      font-size: 18px;
    }

    &:hover {
      background-color: #c0c0c0;
    }
  }

  &__hidden {
    visibility: hidden;
    height: 0;
    padding: 0;
  }

  &__disabled {
    background-color: #e1e1e1;
    opacity: 0.5;
    pointer-events: none;

    &:hover {
      background-color: #efefef !important;
      cursor: auto;
    }
  }
}

.runnersWrapper {
  &__liner {
    display: flex;
    justify-content: center;
    margin-left: auto;
    padding: 0 10px;
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;

    @include md {
      justify-content: center;
      font-weight: 700;
      background-color: #d3d3d3;
      color: #273a47;
    }
  }

  &__linerNumber {
    flex-basis: 50%;
    text-align: center;
    font-size: 10px;
    font-weight: bold !important;
  }

  &__statusLabel {
    display: inline-block;
    width: 100%;
    font-weight: 700 !important;
    font-size: 11px;
    line-height: 32px;
  }

  &__firstRunner {
    border-top: 1px solid #f2f5f5;
  }

  &__scrollContent {
    overflow: auto;
  }

  &__nonBorder {
    border-top: none;

    &:last-child > div {
      border-bottom: none;
    }
  }
}

.runnersHandicap {
  &:nth-last-child(2) > div:nth-child(n + 2) > div {
    border-bottom: 1px solid #f2f5f5;
  }

  &:not(:nth-last-child(2)) {
    margin-bottom: 10px;
  }
}

.combinedTotalWrapper {
  margin-bottom: 10px;
}

.iconCaret {
  transform: rotate(-90deg);

  &::before {
    font-size: 12px !important;
    color: #303030 !important;
  }

  &__down {
    transform: rotate(90deg);
  }
}
