.bet {
  &__margin {
    margin: 5px 0;
  }

  &__back {
    background-color: #A6D8FF;
  }

  &__lay {
    background-color: #FAC9D1;
  }

  &__disabled {
    background-color: #ECECEC;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 12px;
  margin: 0;
  padding: 10px;
  font-size: 14px;
  font-weight: normal;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.dashBorder {
  border-top: 1px dashed #666666;
}

.closeIcon {
  align-self: flex-start;
  margin-left: auto;
  padding-left: 5px;
  cursor: pointer;
}
