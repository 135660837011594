:global {
  .date-range-picker {
    margin-top: -5px;
    .react-datepicker__month-container {
      margin: 20px;
      &:last-child {
        margin-left: 0;
      }
      .react-datepicker__header {
        background: none;
        border: none;
        padding: 0;
      }
      .ui-datepicker-header {
        height: 40px;
        margin: 0;
      }
      .react-datepicker__day-name {
        color: #666666;
      }
      .react-datepicker__day,
      .react-datepicker__day-name {
        margin: 0;
        line-height: 28px;
        min-width: 30px;
        flex-grow: 1;
      }
      .react-datepicker__day {
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 2px;

        &:hover {
          background-color: #fff;
          color: #303030;
          border: 1px solid #0072ac;
        }
      }
      .react-datepicker__day-names,
      .react-datepicker__month {
        margin: 0;
      }
      .react-datepicker__day-names,
      .react-datepicker__week {
        display: flex;
      }
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range {
        background-color: #f5f5f5;
        color: #303030;
        border-radius: 0;

        &:hover {
          border-radius: 2px;
        }
      }
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selecting-range-end,
      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end,
      .react-datepicker__day--selected {
        background-color: #0072ac;
        color: #ffffff;
        border-radius: 2px;
      }
      .react-datepicker__day--today {
        font-weight: initial;
      }
    }
  }
}

.wrapper__mobile {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.title {
  color: #303030;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700 !important;
}

.periods {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: auto;
  gap: 12px;
  margin: 0 -20px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    color: #303030;
    outline: none;
    text-align: center;
    cursor: pointer;
    line-height: 40px;
    padding: 0 24px;
    font-size: 16px;
    background-color: #ffffff;
    border: solid 1px #dddddd;
    border-radius: 4px;
    flex: 1 1 calc(100% / 6);

    &:first-child {
      margin-left: 20px;
    }

    &:last-child {
      margin-right: 20px;
    }

    &:hover {
      background-color: #ececec;
    }
    &__active {
      background-color: #0072ac;
      border-color: transparent;
      color: #ffffff;
    }
  }
}

.panel {
  background-color: #f5f5f5;
  border-radius: 4px 0 0 4px;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  overflow: hidden;
  padding: 20px 0;
  height: 100%;
  box-sizing: border-box;

  &__left {
    order: -1;
  }

  &__top {
    height: auto;
    flex-direction: row;
    white-space: nowrap;
    overflow: auto;
    gap: 12px;
  }

  &__item {
    color: #1e1e1e !important;
    border: none;
    outline: none;
    background: none;
    text-align: left;
    cursor: pointer;
    line-height: 28px;
    padding: 6px 0 6px 20px;

    &:hover {
      background-color: #ddd;
    }
    &__active {
      background-color: #fff !important;
      border-left: 4px solid #ffb80c;
      font-weight: 700;
      padding-left: 16px;
    }
  }
}

.calendar {
  display: flex !important;
  flex-direction: row;
  border: none !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 8px 0 #181e5c26;

  &__placeholder {
    display: flex;
    gap: 12px;
    line-height: 42px;
    font-size: 14px;
    &__date {
      font-size: 16px;
      flex: 1;
      text-align: center;
      border-bottom: solid 1px #dddddd;
    }
  }

  & > div:last-child {
    order: -1;
    margin: 0;
    padding: 0;
    width: auto;
  }

  &__mobile {
    border: none !important;
    box-shadow: none;
    gap: 20px;
    display: flex !important;
    font-size: 16px;
    & > div {
      margin: 0 !important;
      flex-grow: 1;
    }
    & > div:last-child {
      order: initial;
    }

    :global {
      .react-datepicker__day,
      .react-datepicker__day-name {
        line-height: 48px !important;
        font-size: 16px;
      }
    }
  }

  &__input {
    position: relative;
    width: 220px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 10px 30px 10px 10px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    outline: 0;

    &::after {
      font-family: 'custom-icon-font', serif;
      content: '\e923';
      position: absolute;
      right: 10px;
      font-size: 16px;
    }
    &:hover,
    &__active {
      border-color: #3e3e3e;
    }
    &__active::after {
      font-family: 'custom-icon-font', serif;
      content: '\e91c' !important;
    }
    &__disabled {
      opacity: 0.5;
      cursor: default;
      &:hover {
        border-color: #dddddd;
      }
    }
  }
}

.toggle {
  text-align: center;
  color: #0072ac;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  justify-content: center;

  &__icon {
    vertical-align: text-bottom;
    margin-right: 8px;
    font-size: 24px;
  }
}
