.notification {
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 10px;
  border-radius: 4px;
  border: solid 1px #0072ac;
  background-color: #ffffff;

  &__animation {
    opacity: 0;
    transform: translateY(-100%);
    animation: slideInNotification 0.5s ease forwards;
  }

  &__market,
  &__event {
    color: #666666;
  }
  &__content {
    flex-grow: 1;
  }
  &__text {
    margin: 0;
    line-height: 15px;
  }
  &__errorMsg {
    margin: 5px 0 0;
  }
  &__success {
    background-color: #cbecd8;
    border: 1px solid #04a04a;
  }
  &__error {
    background-color: #fac9d1;
    border: 1px solid #d70022;
  }

  &__icon {
    &__loading {
      font-size: 16px !important;
      color: #0072ac;
    }
    &__success {
      color: #04a04a;
      font-size: 14px;

      &::before {
        font-size: 14px !important;
      }

      &::after {
        color: #04a04a;
      }
    }

    &__error {
      &::before {
        font-size: 12px !important;
      }
    }
  }

  &__text {
    span {
      font-weight: bold;
    }
  }
  &__close {
    cursor: pointer;
    font-size: 14px;
  }
}

@keyframes slideInNotification {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
