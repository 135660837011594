.message {
  padding: 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  border-radius: 4px;

  &__success {
    background-color: #cbecd8;
    border: 1px solid #04a04a;
  }

  &__error {
    background-color: #fac9d1;
    border: 1px solid #d70022;
  }

  &__icon {
    font-size: 12px;

    &__success {
      color: #04a04a;

      &::before {
        font-size: 14px !important;
      }

      &::after {
        color: #04a04a;
      }
    }

    &__error {
      &::before {
        font-size: 12px !important;
      }
    }
  }

  &__text {
    span {
      font-weight: bold;
    }
  }
}
