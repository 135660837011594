@import 'styles/variables/variables.scss';

.button {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 1 auto;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: $text-black;
  text-align: center;
  border: 1px solid transparent;
  border-radius: $radius-main;
  box-sizing: border-box;
  cursor: pointer;

  &[class~='biab_primary-btn'][class~='biab_mobile-button'] {
    font-weight: 700;
  }

  &:disabled {
    cursor: auto;
  }

  &:focus {
    border-width: 2px;
    border-color: #ff8624;
  }

  &__secondary,
  &__tertiary {
    font-weight: 400;
  }

  &__secondary {
    background-color: #ddd;

    &:focus {
      border-color: $border-dark-gray;
    }
  }

  &__tertiary {
    border-color: $border-gray;
    background-color: $bg-gray;

    &:hover {
      background-color: #ddd;
    }

    &:focus {
      border-width: 1px;
      border-color: $border-gray;
    }

    &.button__selected {
      color: #fff;
      background-color: $bg-dark;
      border-color: $border-black;

      &:hover {
        background-color: $bg-dark;
      }
    }
  }

  &__outline {
    background-color: transparent;
    border-color: $border-gray;
  }
}
