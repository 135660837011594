.status {
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 22px;
  box-sizing: border-box;

  i {
    font-size: 8px;
  }

  &__positive {
    color: #04a04a;

    i {
      color: inherit;
    }
  }

  &__negative {
    color: #fe3629;

    i {
      color: inherit;
    }
  }

  &__dot {
    color: inherit !important;
  }
}
