.field {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 32px 0;
  border-bottom: 1px solid #ddd;

  &__title {
    margin: 0;
    font-weight: 700 !important;
    line-height: 24px;
  }

  &__label {
    font-weight: 400;
    line-height: 24px;
  }

  &__item {
    display: block;
    padding: 15px 0;
  }

  &__description {
    margin: 4px 0 0 0;
    padding-right: 30px;
    line-height: 20px;
    font-size: 14px;
    color: #999;
  }

  &__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input {
    appearance: none;
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;

    & ~ i {
      visibility: hidden;
    }

    &:checked {
      & ~ .field__label {
        font-weight: 700;
      }

      & ~ .field__icon {
        visibility: visible;
      }
    }
  }
}
