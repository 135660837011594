.header {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;

    &__align__top {
      align-items: flex-start;
    }
  }

  &__left {
    display: flex;
    gap: 40px;
  }

  &__divider {
    border-top: 1px solid #dddddd;
  }

  &__left {
    display: flex;
    align-items: center;
    gap: 40px;
  }
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #303030;
}

.filters {
  display: flex;
  align-items: center;
  gap: 20px;
}

.left {
  display: flex;
  align-items: center;
  column-gap: 20px;
  row-gap: 15px;
  flex-wrap: wrap;

  &__wideGap {
    gap: 40px;
  }
}

.right {
  margin-left: 0;
  display: flex;
  gap: 20px;
  align-items: center;
}

.commissionRange {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  color: #666;
}

.searchBtn {
  gap: 10px;

  &:focus {
    border: none;
    outline: none;
  }
}
