.bet {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 5px;
  width: 300px;
  padding: 10px;
  max-width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  background: #ffffff;
  border: 2px solid #c0c0c0;
  border-radius: 4px;
  font-size: 11px;
  z-index: 9999999;
  transform: translate(-50%, -50%);

  &__totalBets {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: silver;
    border-radius: 50%;
    font-size: 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__live {
    padding: 2px 8px;
    font-weight: bold;
    color: #ffffff;
    background-color: #04a04a;
    border-radius: 2px;
  }

  &__eventName {
    font-weight: 600;
  }

  &__marketName {
    font-weight: 600;
  }

  &__odds {
    height: 20px;
    display: flex;
    align-items: center;
    gap: 2px;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid transparent;

    &__value {
      font-weight: bold;
    }
    &__icon {
      margin-left: 5px;
      font-size: 6px;
    }
    &__up {
      padding: 2px 5px;
      border: 1px solid #04a04a;
      background: #cbecd8;
      i {
        color: #04a04a;
      }
    }
    &__down {
      padding: 2px 5px;
      border: 1px solid #d70022;
      background: #fac9d1;
      i {
        color: #d70022;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: end;
    &__date {
      color: #999999;
    }
    &__topDash {
      padding-top: 5px;
      border-top: 1px dashed #666666;
    }
    &__dateTime {
      display: flex;
      gap: 10px;
    }
    &__center {
      align-items: center;
    }
  }

  &__border {
    margin: 5px 0;
    padding: 0;
    border-top: 1px solid #666666;
  }

  &__closeIcon {
    align-self: flex-start;
    margin-left: auto;
    padding-left: 5px;
    cursor: pointer;
  }
}

.btn {
  border-radius: 4px;
  border: solid 2px transparent;
  height: 25px;
  font-size: 12px;
  margin: 5px 0;
  cursor: pointer;
}

.loader {
  &__wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    opacity: 0.5;
  }

  &__circle {
    transform: scale(0.2);
  }
}
