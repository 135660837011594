.placeholder {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 5px;
  background-color: #ececec;
  border: 1px solid #ececec;
  border-radius: 3px;
  font-size: 10px;
  font-style: normal;
  line-height: 15px;
  color: #3e3e3e;
  box-sizing: border-box;

  &::after {
    top: 50%;
  }
}

.placeholder:hover,
.activePlaceholder {
  border-color: #dddddd;
  background-color: #dddddd;
}

.checkbox {
  gap: 5px;
}

.modal {
  &__dialog {
    z-index: 1000000;
  }
}
