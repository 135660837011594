.search {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 5px 0 10px;
  height: 30px;
  width: 300px;
  box-sizing: border-box;
  color: #ffffff;
  background-color: #666666;
  white-space: nowrap;
  &__mobile {
    width: 100%;
    padding: 0 16px;
    height: 60px;
    gap: 12px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    &__mobile {
      flex: 1;
      color: #ffffff;
      font-size: 14px;
    }
  }

  &__input {
    background-color: transparent;
    border: none;
    font-size: inherit;
    color: inherit;
    flex-grow: 1;
    &::placeholder {
      color: #999999;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      color: #999999;
    }

    &::-ms-input-placeholder {
      color: #999999;
    }
    &:active,
    &:focus-visible {
      border: none;
      outline: none;
    }
    &__mobile {
      font-size: 16px;
    }
  }
  .searchIcon {
    line-height: 1;
    font-size: 16px;
    color: inherit;

    &__mobile {
      font-size: 24px;
      margin-left: 12px;
    }
  }
  .closeBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background: none;
    border: none;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    color: inherit;
    cursor: pointer;

    &__mobile {
      width: 42px;
      height: 42px;
      border-radius: 4px;

      &:active {
        background-color: #999999;
      }
    }
    &__icon {
      color: inherit;
      font-size: 16px;

      &__mobile {
        font-size: 18px;
      }
    }
    &:hover {
      background-color: #999999;
    }
  }
}
