$dropdownWidth: 220px;
$dropdownItemHeight: 30px;

.wrap {
  position: relative;
  width: 220px;
  box-sizing: border-box;
}

.placeholder {
  position: relative;
  width: $dropdownWidth;
  height: $dropdownItemHeight;
  display: flex;
  align-items: center;
  padding: 10px 30px 10px 10px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    border: 1px solid #3e3e3e;
  }

  &__open {
    border: 1px solid #3e3e3e;

    &::after {
      font-family: 'custom-icon-font', serif;
      content: '\e91c' !important;
    }
  }

  &__disabled {
    opacity: 0.5;

    &__hover,
    &:hover {
      cursor: default;
      border-color: #dddddd;
    }
  }

  &::after {
    font-family: 'custom-icon-font', serif;
    content: '\e91b';
    position: absolute;
    right: 10px;
    font-size: 16px;
  }
}

.list {
  all: unset;
  position: absolute;
  top: calc($dropdownItemHeight + 5px);
  width: $dropdownWidth;
  padding: 5px 0;
  display: block;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 5;
  box-shadow: 0 2px 8px 0 rgba(24, 30, 92, 0.15);
}

.option {
  all: unset;
  display: flex;
  align-items: center;
  height: $dropdownItemHeight;
  padding: 0 5px 0 10px;
  box-sizing: border-box;
  cursor: pointer;

  label {
    flex: 1 1 100%;
    height: 100%;
  }

  &:hover {
    background-color: #ececec;
  }
}

.divider {
  height: 10px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  hr {
    margin: 0;
    padding: 0;
    height: 0;
    border-top: 1px solid #dddddd;
  }
}
