.details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.finance {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  line-height: 22px;
  color: #666666;

  &__item {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__value {
    font-weight: 700 !important;
    font-size: 16px;
    color: #303030;
  }
}
