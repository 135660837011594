.notification {
  padding: 18px 12px;

  i {
    font-size: 18px;
  }

  p {
    line-height: 23px;
  }
}
