.button {
  border: 1px solid #999999;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  background-color: #ECECEC;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    font-size: 11px;
  }

  &__big {
    width: 20px;
    height: 20px;
    position: relative;

    &:before {
      position:absolute;
      content:'';
      top:-13px;
      right:-13px;
      left:-13px;
      bottom:-13px;
    }

    .icon {
      font-size: 14px;
    }
  }

  &:hover, &__opened {
    border-color: #666666;
    background-color: #666666;

    .icon {
      color: #ffffff;
    }
  }

  &__active {
    border-color: #FF8624;
    background-color: #F3D55F;

    .icon {
      color: #FF8624;
    }

    &:hover, &__opened {
      background-color: #FF8624;
      border-color: #FF8624;

      .icon {
        color: #ffffff;
      }
    }
  }

  &__mobile {
    height: auto;
    width: auto;
    padding: 7px 15px;
    background-color: #FF8624;
    border: none;
    border-radius: 4px;

     &:hover {
       background-color: #FF8624;
     }

    .icon {
      font-size: 18px;
      color: #ffffff;
    }
  }
}
