.description {
  display: flex;
  gap: 16px;

  &__left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
  }

  &__inPlayIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    background: red;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    color: #fff;
    background: #04a04a;
  }

  &__name {
    font-weight: 700 !important;
    line-height: 24px;
    font-size: 16px;
  }

  &__market {
    color: #303030 !important;
    line-height: 22px;

    &:hover {
      text-decoration: none;
    }
  }

  &__ew {
    line-height: 22px;
    color: #666666;
  }

  &__event {
    line-height: 24px;
  }

  &__odds {
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 24px;
    color: #303030;
  }

  &__type {
    line-height: 22px;
    color: #666666;
  }

  &__pl {
    padding: 2px 0;
  }
}
