@import 'src/styles/mixins/breakpoints';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.title {
  flex: 1 1 auto;
  margin: 0;
  font-size: 24px;
  font-weight: 700 !important;
  line-height: 30px;

  &__small {
    font-size: 20px;
  }
}

.viewAllBtn {
  flex-basis: 200px;
  flex-grow: initial;
  height: 40px;

  @media (max-width: $sm) {
    flex-basis: auto;
    height: 42px;
  }

  button {
    font-size: 14px;

    @media (max-width: $sm) {
      padding: 10px 20px;
    }
  }
}

.list {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  row-gap: 20px;
  column-gap: 40px;

  @media (max-width: $sm) {
    column-gap: 20px;
    justify-content: flex-start;
  }

  &__small {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: $lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__mobile {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 12px;
    column-gap: 20px;

    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__horizontal {
    overflow-x: scroll;
    scrollbar-width: none;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-right: -16px;
    margin-left: -16px;
    padding-left: 16px;
    column-gap: 24px;

    figure {
      width: 300px;

      img {
        width: 300px;
        height: 170px;
      }
    }
  }

  &__small {
    column-gap: 20px;
  }
}
