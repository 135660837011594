.wrap {
  display: flex;
  gap: 10px;
  padding: 12px 13px;
  border-radius: 4px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  line-height: 20px;

  &__error {
    background-color: #fac9d1;
    border-color: #d70022;
    gap: 10px;
  }

  &__info {
    background-color: #a6d8ff;
    border-color: #0072ac;
  }
}

.leftIcon {
  &__info {
    font-size: 22px;
    vertical-align: middle;
  }

  &__error {
    font-size: 22px;

    &::before {
      font-size: inherit !important;
    }
  }
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
}

.lockIcon {
  color: #0072ac !important;
}
