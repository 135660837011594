.header {
  position: sticky;
  top: 0;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
  background-color: #f5f5f5;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-weight: 700 !important;
}

.btn {
  flex: 0 1 auto;
  height: 42px;
  width: 42px;

  &__active {
    color: #0072ac;
    border-color: #0072ac;
  }

  &__disabled {
    color: #c0c0c0;
  }
}
