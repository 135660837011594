.wrap {
  display: flex;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 4px;
  align-items: center;

  &__error {
    background-color: #fac9d1;
    border: 1px solid #d70022;
  }
}

.icon {
  font-size: 12px;

  &::before {
    font-size: inherit !important;
  }
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
}
