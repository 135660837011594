.noBets {
  &__wrapper {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px 46px 20px;
    margin-top: 16px;
  }
  &__icon {
    font-size: 40px;
    line-height: 100%;
    color: #999999;
  }
  &__title {
    font-weight: 700 !important;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 4px;
  }
  &__description {
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    margin-top: 0px;
    margin-bottom: 32px;
  }
  &__btn {
    min-width: 200px;
    height: 42px;
  }
}