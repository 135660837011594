@import 'styles/variables/variables.scss';
@import 'src/styles/mixins/breakpoints';

.wrapper {
  max-width: 500px;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 20px;
  border-bottom: 12px solid #fac9d1;
  &__noBorder {
    border: none;
    border-radius: 0;
  }
}

.border {
  padding: 20px;
  border-radius: 20px 20px 0 0;
  border: 1px solid #dddddd;
  border-bottom: none;
  &__noBorder {
    border: none;
    border-radius: 0;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputColumns {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.inputLabel {
  align-self: flex-start;
  font-size: 14px;
  line-height: 20px;

  &__mobile {
    font-size: 16px;
    line-height: 24px;
  }
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;

  &__mobile {
    gap: 8px;
  }
}

.input {
  outline: 0;
  line-height: 38px;
  padding: 0 15px;
  border: solid 1px #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #303030;

  &__mobile {
    line-height: 50px;
    font-size: 16px;
  }

  &__center {
    text-align: center;
  }
  &:focus {
    border-color: #3e3e3e;
  }
}

.info {
  &__rows {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
    justify-content: center;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 20px;
    font-size: 14px;

    &__mobile {
      line-height: 28px;
    }
  }
  &__label {
    color: #666666;

    &__mobile {
      font-size: 16px;
    }
  }
  &__value {
    color: #303030;

    &__mobile {
      font-size: 18px;
    }

    &__zero {
      color: #999999;
    }
    &__positive {
      color: #04a04a;
    }
    &__negative {
      color: #fe3629;
    }
  }
}

.results {
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  gap: 20px;

  &__mobile {
    flex-direction: column;
    gap: 12px;
    margin-top: 24px;
  }
  &__result {
    padding: 10px 15px 15px;
    border: 1px solid #04a04a;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 12px;
    flex: 1;

    &__mobile {
      padding: 12px 16px 17px;
    }

    &__lose {
      border-color: #fe3629;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700 !important;

    &__mobile {
      gap: 10px;
      font-size: 18px;
      line-height: 28px;
    }

    &__icon {
      font-size: 16px;
      color: #04a04a;

      &__mobile {
        font-size: 24px;
      }

      &__error {
        color: #fe3629;
      }
    }
  }
}

.note {
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  text-align: center;
  margin-bottom: 0;
}

.glossary {
  &__wrapper {
    margin-top: 24px;
    border-radius: 4px;
    border: solid 1px #0072ac;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__header {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    gap: 12px;
    border: none;
    background: none;
    &__icon {
      color: #0072ac;
      font-size: 16px;
    }
    &__title {
      flex: 1;
      text-align: left;
    }
    &__collapse {
      font-size: 16px;
      &__open {
        transform: rotate(180deg);
      }
      &::before {
        color: inherit !important;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    line-height: 20px;
    font-size: 14px;
    p {
      margin: 0;
    }
    &__field {
      font-weight: 700 !important;
    }
  }
}

@media (max-width: 767px) {
  .embeded {
    .inputColumns {
      flex-direction: column;
    }
  }
}
