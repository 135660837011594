.bet {
  position: relative;
  background-color: #ffffff;
  padding: 14px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 6px;
    top: 0;
    left: 0;
    background-color: #a6d8ff;
  }

  &__embedded {
    border-radius: 0px 0px 3px 3px;
  }

  &__lay {
    &:before {
      background-color: #fac9d1;
    }
  }

  &__cancelled {
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;

    &:before {
      content: none;
    }
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ddd;
}
