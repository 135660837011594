.skeletonHeader {
  display: flex;
  gap: 12px;
  padding: 12px 16px;
  background-color: #fff;

  &__link {
    flex: 1;
    height: 42px;
  }

  &__btn {
    width: 42px;
    height: 42px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 16px;
}

.skeletonItem {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 12px 16px 24px;
  background-color: #fff;

  &__rectangle {
    width: 70px;
    height: 48px;
  }

  &__xs {
    width: 40px;
    height: 16px;
  }

  &__s {
    width: 100px;
    height: 16px;
  }

  &__m {
    width: 140px;
    height: 16px;
  }

  &__l {
    width: 200px;
    height: 16px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;

    &__right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  &__market {
    border-radius: 4px;
    border: 1px solid #ddd;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 4px;
      padding: 10px 16px 12px 16px;
      border-bottom: 1px solid #ddd;
      background-color: #f5f5f5;
    }

    &__main {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      padding: 24px 16px 20px;

      &__left {
        display: flex;
        flex-direction: column;
        gap: 22px;

        &__bet {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }

      &__right {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }
  }
}
