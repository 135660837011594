@import 'styles/variables/variables.scss';
@import 'styles/mixins/breakpoints';

.search {
  &__searchBtn {
    min-width: 100px;
    gap: 10px;

    i {
      font-size: 24px;
      color: inherit;
    }

    @media (max-width: $md) {
      flex-grow: 0;
      width: 42px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: $md) {
      width: 100%;
      flex-direction: column;
      align-items: stretch;
      gap: 24px;
    }
  }

  &__searchBarRow {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: $md) {
      align-items: stretch;
      gap: 12px;
    }
  }

  &__inputWrapper {
    position: relative;

    @media (max-width: $md) {
      flex: 1 1 auto;
    }
  }

  &__closeBtn {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    box-sizing: border-box;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: $bg-gray;
    }
  }

  &__inputIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &__search {
      left: 11px;
    }

    &__close {
      font-size: 16px;
      color: #666;

      @media (max-width: $md) {
        color: #303030;
      }
    }
  }

  &__label {
    margin: 0;
    color: $text-gray-dark;
    font-size: 12px;

    @media (max-width: $md) {
      font-size: 14px;
    }
  }
}

.input {
  box-sizing: border-box;
  height: 30px;
  width: 300px;
  padding: 0 35px;
  border: 1px solid $border-black;
  border-radius: $radius-main;
  font-size: 12px;
  outline: none;

  &::placeholder {
    color: #999999;
  }

  @media (max-width: $md) {
    padding-left: 12px;
    height: 42px;
    width: 100%;
    font-size: 16px;
  }
}
