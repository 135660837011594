.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.header {
  background-color: #ffffff;
  padding: 12px 16px;
  display: flex;
  gap: 12px;

  &__link {
    text-align: center;
    padding: 10px 0;
    flex-grow: 1;
    border: solid 1px #dddddd;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    color: #0072ac;
  }

  &__filterBtn {
    flex: 0 1 42px;
    padding: 0;

    &__active {
      background-color: #3e3e3e !important;
      color: #fff !important;
    }
  }
}
