.infoMsg {
  padding: 5px 0;
}
.table {
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  .tableHeader {
    display: flex;
    align-items: center;
    background: #e5e7e8;
    vertical-align: middle;
    text-align: center;
    color: #333333;
    border-top: solid 1px #999999;
    border-bottom: solid 1px #999999;
    .marketCol {
      width: 52%;
      text-align: left;
    }
    .middleCol {
      width: 16.5%;
    }
    .plCol {
      width: 15%;
      text-align: right;
    }
    .dateHeader {
      flex-basis: 3%;
    }
  }
  .descriptionHeader {
    flex: 1 0 25%;
  }
  .dateHeader {
    flex-basis: 3%;
  }

  .tableBody {
    display: flex;
    align-items: center;
    padding: 8px;
    vertical-align: middle;
    text-align: center;
    color: #333333;
    border-top: 1px solid #e1e1e1;
    border-collapse: collapse;
    border-spacing: 0;
    &:first-child {
      border-top: none;
    }
    .small {
      flex-basis: 3%;
    }
  }
  .react-datepicker-wrapper {
    width: auto;
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  font-size: 12px;
  border-bottom: 1px solid #ddd;

  &__mobile {
    border-bottom: solid 1px #d8d8d8;
    line-height: 18px;
  }
}

.commissionRangeMobile {
  padding: 10px;
}

.mobileTableHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 700;
  color: #7f7f7f;
  border-top: 1px solid #d8d8d8;
  span {
    font-weight: 700 !important;
  }
}
.mobileAccountItem {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #d8d8d8;
  align-items: center;
  &:last-child {
    border-bottom: 1px solid #d8d8d8;
  }
  &__description {
    width: 70%;
    color: #7f7f7f;
    font-size: 13px;
    line-height: 18px;
    span.capitalise::first-letter {
      text-transform: uppercase;
    }
    span.capitalise {
      text-transform: lowercase;
    }
  }
  &__balance {
    color: #808080;
    font-weight: 700 !important;
    font-size: 11px;
    margin-left: 65px;
    position: absolute;
    right: 28px;
  }
  i {
    margin-left: 1px;
    position: absolute;
    right: 7px;
  }
}

.highlighted {
  background-color: #f5f5f5;
}
