@import 'styles/variables/variables';

.market {
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid #ddd;

  &__header {
    display: flex;
    align-items: center;
    padding: 7px 4px 9px 16px;
    background-color: #f5f5f5;
    border-radius: 3px 3px 0px 0px;
  }

  &__collapseBtn {
    flex: 0 1 42px;
    border-radius: 50%;
  }

  &__info {
    min-height: 42px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__name {
    color: #303030 !important;
    line-height: 20px;

    &:hover {
      text-decoration: none;
    }
  }

  &__details {
    line-height: 20px;
    color: #666666;
  }

  &__collapseBtn {
    all: unset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;

    &:hover {
      background-color: #ececec;
    }
  }

  &__collapseIcon {
    transition: transform 0.2s;

    &::before {
      color: $icon-black !important;
    }

    &__collapsed {
      transform: rotate(180deg);
    }
  }

  &__footer {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-top: 1px solid #ddd;
  }

  &__cashOutContainer {
    padding: 0;
  }

  &__cashOutContainerContent {
    justify-content: center;
    padding: 0;
  }

  &__cashOutBtn {
    width: auto;
    min-width: 260px;
    height: 42px;
    margin: 0;
    border-radius: 4px;
  }

  &__cashOutSettings {
    width: 42px;
    height: 42px;
    margin: 0;
    border-radius: 4px;
  }
}
