.customLinks {
  display: flex;
  justify-content: center;
  gap: 10px;

  a {
    font-weight: 400;
  }

  a:hover {
    text-decoration: none;
  }
}
