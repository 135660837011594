.bet {
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 500;

  > * {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }

  &__live {
    padding: 2px 8px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #04A04A;
    border-radius: 2px;
  }

  &__eventName {
    font-weight: 600;
  }

  &__marketName {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  &__odds {
    height: 20px;
    display: flex;
    align-items: center;
    gap: 2px;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid transparent;

    span {
      font-weight: bold;
    }
    i {
      margin-left: 5px;
      font-size: 6px;
    }
    &__up {
      padding: 2px 5px;
      border: 1px solid #04A04A;
      background: #CBECD8;
      i {
        color: #04A04A;
      }
    }
    &__down {
      padding: 2px 5px;
      border: 1px solid #D70022;
      background: #FAC9D1;
      i {
        color: #D70022;
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: end;
    a {
      text-decoration: underline;
      cursor: pointer;
    }
    &__center {
      align-items: center;
    }
  }

  &__date {
    color: #999999;
  }

  &__form {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    > * {
      width: 50%;
    }
  }

  &__payout {
    padding-top: 5px;
    border-top: 1px dashed #666666;
  }

  &__border {
    margin: 8px 0 5px 0;
    padding: 0;
    border-top: 1px solid #666666;
  }
}

.closeIcon {
  align-self: flex-start;
  margin-left: auto;
  padding-left: 5px;
  cursor: pointer;
}
