.popUp {
  position: absolute;
  box-shadow: 0 2px 8px 0 rgba(12, 12, 13, 0.2);
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 999999;
  min-width: 430px;
  max-width: 430px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__wrapper {
    display: contents;

    &__mobile {
      display: block;
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      overflow-y: auto;
      max-height: 100dvh;
      z-index: 20001;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__desktop {
    cursor: grab;

    &__grabbing {
      cursor: grabbing;
    }
  }

  &__invisible {
    visibility: hidden;
  }

  &__expandedTables {
    min-width: 710px;
    max-width: 710px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffb80c;
    border-radius: 4px 4px 0 0;
    padding: 9.5px 15px;

    &__mobile {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }

  &__teamNames {
    margin: 0;
    line-height: 1.5;
    font-weight: 600 !important;

    &__text {
      font-size: 14px;
      color: #212121;

      &__mobile {
        font-size: 16px;
      }
    }
  }

  &__closeIcon {
    font-size: 16px;
    color: inherit;

    &__btn {
      cursor: pointer;
      border: none;
      background: none;
      outline: none;
      padding: 0;
      color: inherit;
    }
  }

  &__body {
    padding: 20px 15px;
    border-radius: 0 0 4px 4px;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__betsCountWrapper {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 15px;

    &__count {
      margin: 0;
      font-size: 14px;
      line-height: 1.5;
      color: #3e3e3e;
    }
  }

  &__emptyData {
    height: 60px;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 1.5;
    color: #666666;
  }

  &__medium {
    font-weight: 500;
  }

  &__toggleShowScore {
    border: none;
    background-color: #dddddd;
    border-radius: 4px;
    padding: 5px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: #c0c0c0;
    }

    &__text {
      font-size: 12px;
      line-height: 1.66;
      color: inherit;
    }

    &__icon {
      font-size: 12px;
    }
  }

  &__skeleton {
    &__eventName {
      width: 150px;
      height: 20px;
    }

    &__data {
      width: 100%;
      height: 60px;
    }
  }

  &__backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    opacity: 0.5;
    z-index: 20000;
  }

  &__mobile {
    width: calc(100dvw - 48px);
    min-width: auto;
    max-width: calc(100dvw - 48px);
    position: relative;
    top: 120px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &__betlist {
    width: calc(100dvw - 32px);
    max-width: calc(100dvw - 32px);
  }

  &__emptySpace {
    height: 82px;
    position: relative;
    top: 120px;
  }
}
