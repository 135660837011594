.wrap {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px 5px 8px;
  border-radius: 4px;
  align-items: center;
  border-width: 1px;
  border-style: solid;

  &__error {
    background-color: #fac9d1;
    border-color: #d70022;
  }

  &__success {
    background-color: #cbecd8;
    border-color: #04a04a;
  }
}

.leftIcon {
  &__success,
  &__successUp {
    color: #04a04a;
    font-size: 16px;
  }

  &__success {
    &::before {
      font-size: 14px !important;
    }
  }

  &__error {
    &::before {
      font-size: 12px !important;
    }
  }
}

.closeIcon {
  margin-left: auto;
  cursor: pointer;
  font-size: 12px;
}

.message {
  font-size: inherit;
  margin: 0;

  &__success {
    span {
      font-weight: bold;
    }
  }
}
